.helpful-links__body {
  flex: 0 1 65%;
  padding: 2rem;
}

.helpful-links__body__title {
  font-size: var(--titleFontSize);
}

.helpful-links__body__update {
  font-size: var(--tertiaryFontSize);
  color: var(--secondaryFontColor);
  text-decoration: underline;
}

.helpful-links__body__update__time {
  font-size: inherit;
}

.helpful-links__body__sub-title {
  font-size: var(--primaryFontSize);
  margin-top: 1rem;
  /* margin-bottom: 0.5rem; */
}

.helpful-links__body__paras {
  padding-top: 1rem;
  font-size: var(--secondaryFontSize);
  color: var(--primaryFontColor);
}
