.reviews-container {
  width: 80%;
  margin-top: 3rem;
  margin-inline: auto;
}

.reviews-container__title {
  text-align: center;
  color: var(--titleFontColor);
  font-size: var(--titleFontSize);
}

.reviews {
  display: flex;
  column-gap: 1rem;
  margin-top: 2rem;
}

.review {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0.75rem 0.5rem;
  background-color: var(--bgElevated);
  border: 1px solid var(--borderFontColor);
  border-radius: 0.5rem;
}

.review__title {
  font-size: var(--secondaryFontSize);
  color: var(--titleFontColor);
}

.review__review {
  margin-top: var(--tertiaryFontSize);
  font-size: var(--tertiaryFontSize);
  color: var(--primaryFontColor);
}

.review__rating {
  margin-top: var(--tertiaryFontSize);
  display: flex;
  column-gap: 0.5rem;
}

.review__rating__img {
  width: 1rem;
}

/* Phones and Tablets */
/* @media (max-width: 1200px) {
  .review {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.75rem 0.5rem;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 0.5rem;
    color: rgba(255, 255, 255, 0.85);
    text-align: center;
    background-color: #1f1f1f;
  }

  .review__title {
    margin-bottom: 1rem;
  }

  .review__review {
    color: rgba(255, 255, 255, 0.65);
    font-size: 0.835rem;
    margin-bottom: 1rem;
  }

  .review__rating {
    display: flex;
    column-gap: 0.5rem;
  }

  .review__rating > img {
    width: 1.25rem;
    height: 1.25rem;
  }
} */
