.forms__form {
  display: flex;
  width: 60%;
  margin-top: 2rem;
  margin-inline: auto;
  border: 1px solid var(--borderFontColor);
  border-radius: 0.5rem;
  overflow: hidden; /* For image border radius*/
  background-color: var(--bgElevated);
}

.forms__form__img-cont {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.forms__form__img-cont__img {
  flex-grow: 1;
  object-fit: cover;
}

.forms__form__img-cont__caption {
  font-size: var(--quaternaryFontSize);
  color: var(--secondaryFontColor);
}

.forms__form__img-cont__caption__link {
  font-size: inherit;
  color: var(--primary);
}

.contactForm-container {
  flex: 1;
  padding: 1.5rem 2rem;
}

.contactForm-container__title {
  text-align: center;
  color: var(--titleFontColor);
  font-size: var(--primaryFontSize);
}

.forms__form__contact {
  margin-top: 1rem;
  color: var(--primaryFontColor);
}

.forms__form__contact__desc {
  text-align: center;
  font-size: var(--secondaryFontSize);
}

.forms__form__contact-form {
  margin-top: 1rem;
  text-align: center;
}

.forms__form__contact-form__fields {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  text-align: start;
}

.forms__form__contact-form__fields__input,
.forms__form__contact-form__fields__textarea {
  font-size: var(--tertiaryFontSize);
  padding: 0.25rem 0.5rem;
  color: var(--secondaryFontColor);
  background-color: var(--bg);
  border: 1px solid var(--borderFontColor);
  border-radius: 0.25rem;
}

.forms__form__contact-form__fields__textarea {
  resize: none;
  height: 10rem;
}

.forms__form__contact-form__fields__restrictions {
  font-size: var(--quaternaryFontSize);
}
.forms__form__contact-form__fields__label,
.forms__form__contact-form__fields__label__required,
.forms__form__contact-form__fields__upload,
.forms__form__contact-form__fields__checkbox-label {
  font-size: var(--tertiaryFontSize);
}

.forms__form__contact-form__fields__restrictions,
.forms__form__contact-form__fields__label__required {
  color: var(--primary);
}

.forms__form__contact-form__fields__checkbox-label {
  color: var(--secondaryFontColor);
}

.forms__form__contact-form__fields__checkbox-link {
  font-size: inherit;
  color: var(--primary);
}

.forms__form__contact-form__fields__checkbox-box {
  margin-right: 0.5rem;
}

.forms__form__contact-form__button {
  width: 200px;
  margin-top: 1rem;
}
