.forms {
  padding-block: 2rem;
}

.forms__links {
  width: min-content;
  margin-inline: auto;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border: solid 1px var(--borderFontColor);
}

.forms__links__link,
.forms__links__link--active {
  display: block;
  width: 6rem;
  text-align: center;
  padding: 0.2rem 0.5rem;
  color: var(--white);
  text-decoration: none;
  background-color: var(--bgElevated);
}

/* This overrides the previous rule for the bg color */
.forms__links__link--active {
  background-color: var(--primary);
}

/* start of actual form styling */
