.genericButton {
  padding: 0.3rem 0.5rem;
  margin-top: 0.5rem;
  font-size: var(--tertiaryFontSize);
  color: var(--white);
  border: none;
  border-radius: 6px;
  background-color: var(--primary);
}

.genericButton:hover {
  background-color: var(--primaryDark);
}
