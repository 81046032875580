/* Laptops, Monitors, TVs */
.navbar {
  width: 100%;
  position: sticky;
  top: 0;
  color: var(--titleFontColor);
  background-color: var(--bg);
  border-bottom: 1px solid var(--borderFontColor);
  box-shadow: 0px 9px 13px rgba(0, 0, 0, 0.1);
  z-index: 1; /* for the hero section */
}

.contact-info {
  background-color: var(--primary);
  font-size: var(--tertiaryFontSize);
  text-align: center;
  padding-block: 0.1rem;
}

.contact-info__number,
.contact-info__email,
.contact-info__form {
  color: inherit;
  font-size: inherit;
  font-weight: 600;
}

.main-container {
  width: 80%;
  display: flex;
  align-items: center;
  margin-inline: auto;
}

.navbar__img {
  width: 6rem;
  height: 3rem;
}

/* Links */
.navbar__links {
  list-style-type: none;
  height: 4rem;
  display: flex;
  padding: 0;
  margin-inline: auto;
  transform: translateX(-3rem);
}

.navbar__links__item {
  transform: skew(-20deg);
}

.navbar__links__item:hover {
  background: var(--primaryBgHover);
}

.navbar__links__item--active {
  background: var(--primaryBgActive);
}

.navbar__links__item__anchor {
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}

.navbar__links__item__anchor__text {
  transform: skew(20deg);
}

.navbar__links__item__anchor--active {
  border-bottom: 4px solid var(--primary);
}

.navbar__links__item__anchor:link,
.navbar__links__item__anchor:visited {
  color: inherit;
}
/* Links Finished */

/* .navbar__phone-menu,
  .sec-container {
    display: none;
  } */
/* } */

/* Mid Laptops - Mid Desktops */
/* @media (min-width: 1201px) and (max-width: 1600px){
  .main-container {
    width: 90%;
  }
} */

/* For Tablets and Phones */
/* @media (max-width: 1200px) {
  /* Common between devices 
  .navbar {
    z-index: 1; /*Required for opacity in carousel arrows
    width: 100%;
    position: fixed;
    color: rgba(255, 255, 255, 0.85);
    background-color: #141414;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0px 9px 13px rgba(0, 0, 0, 0.1);
  }

  .main-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .navbar__links,
  .navbar__phone {
    display: none;
  }

  .navbar__img {
    width: 5rem;
    height: 2.5rem;
  }

  .navbar__phone-menu line {
    fill: none;
    stroke: #bda042;
    stroke-width: 4;
  }

  /* Common Finished 

  /* Sec Container 
  .navbar__dropdown {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    background-color: #221e15;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .navbar__dropdown--hidden {
    display: none;
  }

  /* Links 
  .navbar__dropdown__links__item {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar__dropdown__links__item--active {
    background: #352e1a;
  }

  .navbar__dropdown__links__item__anchor {
    width: 100%;
    padding-block: 0.5rem;
    text-align: center;
    text-decoration: none;
  }

  .navbar__dropdown__links__item__anchor:link {
    color: inherit;
    cursor: pointer;
  }

  .navbar__dropdown__links__item__anchor:visited {
    color: inherit;
    cursor: pointer;
  }
  /* Links Finished 

  .navbar__contact {
    display: flex;
  }

  .navbar__contact div {
    flex: 1;
    text-align: center;
  }

  .navbar__contact__form {
    padding-block: 0.125rem;
    background-color: #bda042;
  }

  .navbar__contact__number {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* Sec Container Finished 
} */

/* @media (min-width: 481px) and (max-width: 1200px) {
  .navbar__img {
    width: 6rem;
    height: 3rem;
  }

  .navbar__phone-menu {
    width: 2.3rem;
    height: 1.8rem;
  }
}

@media (min-width: 966px) and (max-width: 1200px) {
  .main-container {
    padding: 0.75rem 2rem;
  }
}

/* Tablets 
@media (min-width: 481px) and (max-width: 965px) {
  .main-container {
    padding: 0.75rem 1.5rem;
  }
}

/* Phones 
@media (max-width: 480px) {
  .main-container {
    padding: 0.5rem 1rem;
  }

  .navbar__img {
    width: 5rem;
    height: 2.5rem;
  }

  .navbar__phone-menu {
    width: 2rem;
    height: 1.6rem;
  }
} */
