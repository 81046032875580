.genericLinkButton {
  padding: 0.3rem 0.5rem;
  color: var(--white);
  border-radius: 6px;
  text-decoration: none;
  font-size: var(--primaryFontSize);
  background-color: var(--primary);
}

.genericLinkButton:hover {
  background-color: var(--primaryDark);
}

.genericLinkButton--small-font {
  font-size: var(--tertiaryFontSize);
}

.genericLinkButton--dark {
  background-color: var(--bg);
}

.genericLinkButton--dark:hover {
  background-color: var(--bgElevated);
}
