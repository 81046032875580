.helpful-links {
  flex-grow: 1;
  display: flex;
  color: var(--titleFontColor);
  background-color: var(--bg);
}

.helpful-links__container {
  flex: 0 1 20%;
  border-right: solid 1px var(--borderFontColor);
}

.helpful-links__container__list {
  list-style-type: none;
  position: fixed;
  right: 80%;
  padding: 0;
}

.helpful-links__container__list__anchor,
.helpful-links__container__list__anchor--active {
  text-decoration: none;
  display: block;
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  font-size: var(--secondaryFontSize);
  color: var(--primaryFontColor);
}

.helpful-links__container__list__anchor--active {
  background-color: var(--bgElevated);
  color: var(--white);
  box-shadow: inset -4px 0 0 0 var(--primary);
}

.helpful-links__container__list__anchor:hover {
  color: var(--white);
}