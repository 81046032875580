.subtitle-container__title {
  text-decoration: underline;
  color: var(--titleFontColor);
  font-size: var(--secondaryFontSize);
}

/* @media (min-width: 1201px) {
  .footer__links__title,
  .footer__details__title,
  .footer__looking * {
    font-size: 0.835rem;
  }
} */

/* @media (max-width: 1200px) {
  .footer__container {
    background-color: #141414;
  }

  .footer__social,
  .footer__links {
    display: flex;
    flex-direction: column;
  }

  .footer__social {
    align-items: center;
    justify-content: space-between;
    font-size: 0.835rem;
  }

  .footer__social__links {
    display: flex;
    justify-content: space-between;
    width: 5rem;
  }

  .footer__social__links__icons {
    width: 1rem;
  }

  .footer__social__links__license {
    font-size: 0.7rem;
  }

  .footer__links__list {
    list-style-type: none;
    padding: 0;
  }

  .footer__links__list > li > a {
    font-size: 0.667rem;
  }

  .footer__links__list > li > a:hover {
    color: #fff;
    text-decoration: 3px underline #a48b3b;
  }
} */

/* @media (min-width: 966px) { */
.footer__container {
  width: 100%;
  padding: 1.5rem 3rem;
  color: var(--primaryFontColor);
  background-color: var(--bgElevated);
  border-top: 1px solid var(--borderFontColor);
}

.footer {
  display: flex;
  justify-content: space-around;
  margin-bottom: 2rem;
}

/* 
For phone footer
.footer__img,
  .footer__container__img,
  .footer__details__title__phone {
    display: none;
  } */

.footer__logo {
  width: 12rem;
  height: 6rem;
  /* margin-top: 1rem; */
}

.footer__quote {
  display: block;
  margin-top: 3rem;
  color: var(--primaryFontColor);
}

.footer__primary,
.footer__secondary {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.footer__primary__office-body,
.footer__primary__license-body {
  margin-top: var(--tertiaryFontSize);
  font-size: var(--tertiaryFontSize);
}

.footer__primary__links__list {
  list-style-type: none;
  margin-top: var(--tertiaryFontSize);
  padding: 0;
}

.footer__primary__links__list__item,
.footer__primary__links__list__item__anchor {
  font-size: var(--tertiaryFontSize);
}

.footer__primary__links__list__item__anchor {
  text-decoration: none;
  color: var(--primaryFontColor);
}

.footer__primary__links__list__item__anchor:hover {
  text-decoration: 3px underline var(--primary);
  color: var(--white);
}

.footer__secondary__buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: var(--tertiaryFontSize);
  margin-top: var(--tertiaryFontSize);
}

.footer__secondary__socials {
  display: flex;
  justify-content: space-between;
  margin-top: var(--tertiaryFontSize);
}

.footer__secondary__socials__icon {
  width: 1rem;
}

.footer__tertiary {
  width: 50%;
  border-radius: 0.5rem;
  box-shadow: 0.5rem 0.5rem 1rem #121212;
}

.signature {
  margin-top: var(--tertiaryFontSize);
  padding-left: 1rem;
  font-size: var(--tertiaryFontSize);
}
/* } */

/* @media (min-width: 966px) and (max-width: 1200px) {
  .footer__price {
    width: 10rem;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 1rem;
  }

  .footer__price__button {
    font-size: 1rem;
    border: none;
    border-radius: 6px;
    color: #fff;
    background-color: #a48b3b;
  }

  .footer__price__button:hover {
    background-color: #826f32;
  }
}

@media (max-width: 965px) {
  .footer {
    display: flex;
    justify-content: space-around;
    padding-block: 1.5rem;
    background-color: #1f1f1f;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.65);
  }

  .footer__price,
  .footer__social__title {
    display: none;
  }
}

@media (min-width: 626px) and (max-width: 965px) {
  .footer__container__img {
    display: none;
  }

  .footer__img {
    width: 9rem;
    height: 4.5rem;
    align-self: center;
  }
}

@media (max-width: 625px) {
  .footer__container__img {
    width: 90%;
    margin-inline: auto;
  }

  .footer__img {
    display: none;
  }
} */
