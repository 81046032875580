.about {
  width: 60%;
  margin-inline: auto;
  margin-bottom: 2rem;
  color: var(--titleFontColor);
}

.about__title {
  padding-top: 1.5rem;
  font-size: var(--titleFontSize);
  text-align: center;
}

.about__paras {
  font-size: var(--secondaryFontSize);
  padding-top: 1rem;
}
