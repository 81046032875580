.home {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* @media (min-width: 1201px) { */

/* } */

/* @media (max-width: 1200px) {
  .bottom-line,
  .right-line,
  .left-line,
  .corner,
  .space {
    display: none;
  }
}

@media (min-width: 481px) and (max-width: 1200px) {
  .home {
    padding-top: 6.25rem;
  }
}

@media (max-width: 480px) {
  .home {
    padding-top: 5.25rem;
  }
} */
