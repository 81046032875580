.service-details {
  width: 60%;
  margin-inline: auto;
  margin-bottom: 2rem;
  color: var(--titleFontColor);
}

.service-details__title {
  padding-top: 1.5rem;
  font-size: var(--titleFontSize);
  text-align: center;
}

.service-details__para {
  color: var(--primaryFontColor);
  padding-top: 1rem;
}

.service-details__para,
.service-details__list li {
  font-size: var(--secondaryFontSize);
}

.service-details__list {
  margin-top: 1rem;
  color: var(--primaryFontColor);
}
