/* My CSS */

#root {
  font-family: Arial, Tahoma, Verdana, sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Resetting CSS */

*,
*::before,
*::after {
  box-sizing: border-box;
  --primary: #a48b3b;
  --primaryDark: #826f32;
  --bg: #141414;
  --bgElevated: #1f1f1f;
  --primaryBgHover: #352e1a;
  --primaryBgActive: #221e15;
  --white: #fff;
  --black: #000;
  --titleFontColor: rgba(255, 255, 255, 0.85);
  --primaryFontColor: rgba(255, 255, 255, 0.65);
  --secondaryFontColor: rgba(255, 255, 255, 0.45);
  --disabledFontColor: rgba(255, 255, 255, 0.3);
  --tertiaryFontColor: rgba(255, 255, 255, 0.25);
  --borderFontColor: rgba(255, 255, 255, 0.2);
  --heroFontSize: 56px;
  --titleFontSize: 30px;
  --primaryFontSize: 24px;
  --secondaryFontSize: 20px;
  --tertiaryFontSize: 16px;
  --quaternaryFontSize: 14px;
  --quinaryFontSize: 12px;
}
* {
  margin: 0;
  font-variant-numeric: tabular-nums;
  line-height: calc(100% + 8px);
}

html,
body {
  height: 100%;
}
body {
  font-family: Arial, Tahoma, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
input,
button,
textarea,
select {
  font: inherit;
  line-height: calc(100% + 8px);
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
#root,
#__next {
  isolation: isolate;
  background-color: #141414;
}

/* Laptops, Monitors, TVs */
@media (min-width: 1201px) {
  * {
    font-size: 24px;
  }
}

/* Tablets & Phones*/
@media (max-width: 1200px) {
  * {
    font-size: 20px;
  }
}
