.cookies {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 15rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 0.75rem;
  border-radius: 10px;
  background-color: var(--bg);
  color: var(--titleFontColor);
  z-index: 1;
}

.cookies__desc,
.cookies__quest {
  font-size: var(--tertiaryFontSize);
}

.cookies__desc__link {
  font-size: inherit;
  color: var(--primary);
}

.cookies__quest {
  margin-top: 0.5rem;
}

.cookies__buttons {
  display: flex;
  justify-content: center;
  column-gap: 1rem;
}
