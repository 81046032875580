.clients-container {
  width: 80%;
  margin-top: 3rem;
}

.clients-container__title {
  text-align: center;
  color: var(--titleFontColor);
  font-size: var(--titleFontSize);
}

.clients {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 2rem;
}

.clients__bradford,
.clients__clipsal,
.clients__dynamic,
.clients__neca,
.clients__redox {
  width: 200px;
  height: fit-content;
}

.clients__clipsal {
  height: 40px;
}
