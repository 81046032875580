.projects-container {
  width: 100%;
  margin-top: 3rem;
  padding: 2rem 6rem;
  background-color: var(--bgElevated);
}

.projects-container__title {
  text-align: center;
  color: var(--titleFontColor);
  font-size: var(--titleFontSize);
}

.projects-container__selection {
  display: flex;
  justify-content: center;
  padding: 0;
  margin-top: 0.5rem;
  list-style-type: none;
  color: var(--titleFontColor);
}

.projects-container__selection__item {
  margin-left: 1rem;
  transform: translateX(-1rem);
  cursor: pointer;
}

.projects-container__selection__item--active {
  border-bottom: 4px solid var(--primary);
}

.projects {
  display: flex;
  column-gap: 1rem;
  margin-top: 1rem;
  padding: 0;
  overflow-x: auto;
}

::-webkit-scrollbar {
  height: 8px;
}

.projects::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 10px;
}

.projects::-webkit-scrollbar-track {
  background-color: black;
  border-radius: 10px;
}

.projects__item {
  flex-shrink: 0;
  list-style-type: none;
  width: 15rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-size: cover;
  border-radius: 0.5rem;
  color: var(--titleFontColor);
  box-shadow: inset 0 -3rem 2rem -1rem var(--bg);
}

.projects__item__title {
  padding-bottom: 0.25rem;
}
