.services-container {
  width: 60%;
  margin-top: 3rem;
  margin-inline: auto;
}

.services-container__title {
  text-align: center;
  color: var(--titleFontColor);
  font-size: var(--titleFontSize);
}

.services {
  display: flex;
  justify-content: center;
  column-gap: 1rem;
  margin-top: 2rem;
}

.services__elec,
.services__data {
  width: 18rem;
  color: var(--titleFontColor);
  padding: 4rem 1rem;
  border: 1px solid var(--borderFontColor);
  border-radius: 0.5rem;
}

.services__elec,
.services__data {
  background-size: 175%;
  background-position: 40% 0%;
  background-repeat: no-repeat;
  transition: width 500ms ease-in-out;
}

.services__elec {
  /* background-image: url("../assets/elec20.png"); */
  background-image: url("../assets/elec.jpg");
  background-size: cover;
}

.services__data {
  /* background-image: url("../assets/data20.png"); */
  background-image: url("../assets/data.jpg");
  filter: blur(1);
  background-size: cover;
}

.services__elec:hover,
.services__data:hover {
  width: 19rem;
}
