.hero-container {
  width: 100%;
  height: 35rem;
  position: relative;
  background-image: url(../assets/hero-1.jpg);
  /* background-image: url(../assets/sydney60.png); */
  background-position: 0% 80%;
  background-repeat: no-repeat;
  background-size: 110%;
  box-shadow: inset 0 -10rem 3rem -5rem var(--bg);
}

.hero-details {
  position: absolute;
  transition: bottom 1000ms;
}

.hero-details,
.hero-details-appear {
  left: 8%;
  bottom: 40%;
  opacity: 0;
}

.hero-details-appear-done {
  bottom: 34%;
  opacity: 1;
  transition: bottom 1000ms, opacity 1000ms;
}

.hero-details__title {
  color: var(--white);
  font-size: var(--heroFontSize);
}

.hero-details__buttons {
  width: fit-content;
  display: flex;
  column-gap: 1rem;
  margin-top: 1rem;
}

.hero-details__title,
.hero-details__buttons {
  background-color: #00000055;
  box-shadow: 0 0 10px 15px #00000055;
}

.downArr {
  position: absolute;
  left: 50%;
  bottom: 5%;
  transform: translateX(-50%);
}
